<template>
  <div class="label_list">
    <template v-if="dataList.length > 0">
      <router-link :to="`/labelDetail/${item.labelName}`" v-for="item in dataList" :key="item.id" >
        <svg-icon icon-class="hashtag" />
        <div>
          <h3>{{ item.labelName }}</h3>
          <p>{{ item.standardNums }}份标准</p>
        </div>
      </router-link>
    </template>
    <a-empty v-else />
  </div>
</template>

<script>
export default {
  props: ['dataList']
}
</script>

<style lang='scss'>
  .label_list {
    padding: 0 10px 20px;
    display: flex;
    flex-wrap: wrap;

    & > a {
      display: flex;
      width: 280px;
      height: 75px;
      padding: 15px;
      margin-right: 20px;
      margin-bottom: 10px;
      text-decoration: none;

      &:hover {
        background: #f7f7f7;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      .svg-icon {
        width: 22px;
        height: 22px;
        color: #05A081;
        margin-right: 8px;
      }

      & > div {
        & > h3 {
          font-size: 16px;
          color: #1d2129;
          line-height: 22px;
          margin-bottom: 5px;

          &:hover {
            color: #05A081;
          }
        }

        & > p {
          font-size: 12px;
          color: #86909c;
        }
        
      }
    }

    .arco-empty {
      padding: 60px 0 80px;
    }
  }
</style>