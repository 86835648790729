<template>
  <div id="standardInformationBase">
    <LeftTree></LeftTree>
    <div class="standard_right">
      <SearchPanel 
      :search=" $route.params.type == 'LABEL' ? search1 : search" 
      :searchData="searchData"
      @search="searchClick"></SearchPanel>
      <template v-if=" $route.params.type == 'LABEL'" >
        <LabelList :dataList="labelList"></LabelList>
      </template>
      <template v-else>
        <CommonListCom :typeList="typeList" :show="show" @change="changeClick" @clear="clearClick">
          <template #right><a-checkbox @change="checkedClick" v-model="checked">隐藏已废止的标准</a-checkbox></template>
          <h3>共找到 <span>{{ totalNum }}</span> 份标准</h3>
        </CommonListCom>
        <StandardList :dataList="dataList"></StandardList>
        <CommonPagination
          :pageNum="pageNum"
          :pageSize="pageSize"
          :totalNum="totalNum"
          placement="right"></CommonPagination>
      </template>
    </div>
  </div>
</template>

<script>
import LeftTree from './components/LeftTree.vue'
import StandardList from './components/StandardList.vue'
import LabelList from './components/LabelList.vue'
import SearchPanel from './components/SearchPanel.vue'
import CommonListCom from '@/components/CommonListCom.vue'
import CommonPagination from '@/components/CommonPagination.vue'
import merge from 'webpack-merge'
export default {
  components: {
    LeftTree,
    SearchPanel,
    CommonListCom,
    CommonPagination,
    StandardList,
    LabelList
  },
  data() {
    return { 
      pageNum: parseInt(this.$route.query.pager) || 1,
      pageSize: 15,
      totalNum: 0,
      typeList: [
        { title: '默认排序', type: 1, key: 'publish' },
        { title: '发布时间', type: 2, key: 'sort' },
        { title: '实施时间', type: 2, key: 'sort' },
      ],
      checked: true,
      dataList: [],
      searchData: {
        sort: '',
        searchNumber: '',
        standardClassification: ['ALL'],
        scopeReleaseDate: ['ALL'],
        standardState: ['ALL']
      },
      search: {
        input: { key: 'searchNumber', place: '搜索标准号或标准名称' },
        list: [
          { name: '标准类别', list: 'typeList', key: 'standardClassification' },
          { name: '发布时间', list: 'timeList', key: 'scopeReleaseDate' },
          { name: '标准状态', list: 'statusList', key: 'standardState' },
        ]
      },
      search1: {
        input: { key: 'labelName', place: '搜索标签名字' },
      },
      labelList: [],
      show: false,
      searchC: false
    }
  },
  watch: {
    '$route.params.type': {
      handler(n, o) {
        if(n == 'LABEL') {
         this.showLabel()
        }else {
          this.labelList = [];
          this.clearClick();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    showPage() {
      if(this.searchC) return;
      else this.searchC = true
      this.$API.StandardList({
        cateId: this.$route.params.type == 'ALL' ? '' :parseInt(this.$route.params.type),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        hidden: this.checked ? 'Y' : 'N',
        standardClassification: this.searchData.standardClassification[0] == 'ALL' ? '' :  this.searchData.standardClassification[0],
        scopeReleaseDate: this.searchData.scopeReleaseDate[0] == 'ALL' ? '' :  parseInt(this.searchData.scopeReleaseDate[0]),
        standardState: this.searchData.standardState[0] == 'ALL' ? '' :  this.searchData.standardState[0],
        sort: parseInt(this.searchData.sort),
        searchNumber: this.searchData.searchNumber,
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total;
          this.searchC = false;
          window.scrollTo(0, 0)
        }
      })
    },
    checkedClick() {
      this.pageNum = 1;
      this.show = true;
      this.showPage()
    },
    showLabel() {
      this.$API.LabelList({
        labelName: this.searchData.labelName || ''
      }).then(res => {
        if(res.data.code == 200) {
          this.labelList = res.data.data;
        }
      })
    },
    changeClick(data) {
      this.search.sort = null;
      if(data.index == 1) {
        this.searchData.sort = data.type == 1 ? 0 : 1
      }else if(data.index == 2){
        this.searchData.sort = data.type == 1 ? 2 : 3
      }else {
        this.searchData.sort = ''
      }
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.pageNum = 1;
      this.show = true;
      this.showPage()
    },
    searchClick(data) {
      if(data.key == 'labelName') {
        this.searchData.labelName = data.value
        this.showLabel()
      }else {
        this.searchData[data.key] = data.value;
        this.pageNum = 1;
        this.$router.replace({
          query: merge(this.$route.query, { pager: 1 }),
        });
        this.show = true;
        this.showPage()
      }
    },
    clearClick() {
      this.searchData = {
        sort: '',
        searchNumber: '',
        standardClassification: ['ALL'],
        scopeReleaseDate: ['ALL'],
        standardState: ['ALL']
      };
      this.show = false;
      this.checked = true;
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage();
    }
  }
  
}
</script>

<style lang='scss'>
  #standardInformationBase {
    width: 1200px;
    margin: 0 auto;
    display: flex;

    .standard_right {
      width: calc(100% - 300px);
      min-height: calc(100vh - 328px);
    }

    
  }
</style>