<template>
  <div class="search_panel">
    <a-input-search 
      v-model="searchData[search.input.key]" 
      :placeholder="search.input.place"
      @search="searchClick"
      @press-enter="searchClick"
      @input="inputClick"/>
    <div v-if="search.list" class="list">
      <div class="item" v-for="(item, index) in search.list" :key="index">
        <span>{{ item.name }}</span>
        <div>
          <a-tag 
            checkable 
            @check="checkClick(item.key, line.value, item.type)"
            :checked="searchData[item.key].find(line1 => line1 == line.value) ? true : false " 
            v-for="line in CONST[item.list]" 
            :key="line.value">{{ line.label }}</a-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CONST } from '@/assets/js/CONST.js'
export default {
  props: ['searchData', 'search'],
  computed: {
    CONST() { 
      this.search.list.forEach(item => {
        CONST[item.list].forEach(line => {
          if(line.value == 0) line.checked = true
          else line.checked = false
        })
      })
      return CONST 
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    checkClick(key, value, type) {
      this.searchData[key][0] = value;
      this.$emit('search', {
        key: key,
        value: [value]
      })

    },
    searchClick() {
      if(!this.$store.state.loginIn && this.$route.params.type != 'LABEL') return this.$store.commit('open_type', 1)
      this.$emit('search', {
        key: this.search.input.key,
        value: this.searchData[this.search.input.key]
      })
    },
    inputClick(value) {
      if(!value) {
        this.$emit('search', {
          key: this.search.input.key,
          value: value
        })
      }else {
        if(this.$route.params.type == 'LABEL') {
          this.$emit('search', {
            key: this.search.input.key,
            value: this.searchData[this.search.input.key]
          })
        }
      }
    }
  }
}
</script>

<style lang='scss'>
  .search_panel {
    padding: 0 10px;
    margin-bottom: 20px;

    .arco-input-wrapper {
      margin: 6px 0;
      padding: 0 10px;
    }

    .list {
      margin-top: 20px;
      background: #fafafa;
      border-radius: 4px;
      padding: 15px 0;

      .item {
        padding: 11px 25px;
        display: flex;

        & > span {
          font-size: 14px;
          font-weight: 600;
          color: #1d2129;
          line-height: 28px;
          margin-right: 16px;
          width: 70px;
        }

        & > div {
          width: calc(100% - 86px);

          .arco-tag {
            height: 28px;
            border-radius: 4px;
            padding: 0 15px;
            margin-right: 12px;
            line-height: 28px;
            font-size: 14px;
            border: 0;

            &:last-child {
              margin-right: 0;
            }
          }

          .arco-tag-checked {
            background: #05A081;
            color: #fff;
          }
        }
      }
    }
  }
</style>