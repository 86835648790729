<template>
  <div class="standard_list">
    <template v-if="dataList.length > 0">
      <div class="standard_item" v-for="item in dataList" :key="item.standardId">
        <div>
          <h3 @click="detailClick(item.standardNumber)">{{ item.standardNumber || '-' }}</h3>
          <p class="title_one">{{ item.chineseName || '-' }}</p>
          <p class="title_two">{{ item.englishName || '-' }}</p>
          <div class="clearfix">
            <div class="pull-left">
              <span :class="[ 'grey', {
                'green': item.standardState == 'ST',
                'red': item.standardState == 'WT',
                'orange': item.standardState == 'NST'
              }]">{{ item.standardStateName }}</span>
              <span>发布日期:  {{ item.releaseDate && item.releaseDate.split(' ')[0] || '-' }}</span>
              <span>实施日期:  {{ item.implementDate && item.implementDate.split(' ')[0] || '-' }}</span>
              <span v-if="item.abolishDate">废止日期:  {{ item.abolishDate.split(' ')[0] }}</span>
            </div>
            <div class="pull-right">
              <span class="type">{{ item.organizationName }}</span>
              <a-button type="primary" @click="detailClick(item.standardNumber)">去查看<icon-right /></a-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <a-empty v-else />
  </div>
</template>

<script>
export default {
  props: ['dataList'],
  methods: {
    detailClick(id) {
      window.open('/standardDetail?id=' + id)
    }
  }
}
</script>

<style lang='scss'>
  .standard_list {
    .standard_item {
      padding: 0 10px;

      &:hover {
        background: #f7f7f7;
      }

      & > div {
        padding: 30px 0;
        border-bottom: 1px solid #dcdfe6;
      }

      h3 {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 28px;
        cursor: pointer;

        &:hover {
          color: #25B392;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
      .title_one {
        color: #4e5969;
        margin-bottom: 5px;
      }

      .title_two {
        margin-bottom: 10px;
        color: #86909c;
      }

      .pull-left {

        & > span {
          margin-right: 30px;
          font-size: 12px;
          color: #86909c;
          line-height: 28px;

          &:first-child {
            width: 64px;
            height: 20px;
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            display: inline-block;
            line-height: 20px;
            text-align: center;
            margin-right: 15px;
            margin-top: 4px;
          }
        }
      }

      .pull-right {
        display: flex;
        align-items: center;

        .type {
          width: 223px;
          display: inline-block;
          font-size: 14px;
          text-align: right;
          color: #4e5969;
          line-height: 28px;
          margin-right: 15px;
          white-space: nowrap; 
          text-overflow: ellipsis; 
          overflow: hidden;
        } 

        .arco-btn {
          width: 84px;
          height: 28px;
          line-height: 26px;
          font-size: 14px;
          border-radius: 4px;
        }
      }
    }

    .arco-empty {
      padding: 100px 0;
      border-bottom: 1px solid #DCDFE6;
    }
  }
</style>