export const CONST = {
  typeList: [
    { label: '全部', value: 'ALL'},
    { label: '国家标准', value: 'ZGJ' },
    { label: '行业标准', value: 'ZHY' },
    { label: '地方标准', value: 'ZDF' },
    { label: '团体标准', value: 'ZTT' },
    { label: '国际标准', value: 'GJ' },
    { label: '国外标准', value: 'GW' },
    // { label: '其它标准', value: 7 },
  ],
  timeList: [
    { label: '全部', value: 'ALL'},
    { label: '近三个月', value: '0' },
    { label: '近一年', value: '1' },
    { label: '近两年', value: '2' },
    { label: '近三年', value: '3' },
  ],
  statusList: [
    { label: '全部', value: 'ALL'},
    { label: '现行', value: 'ST' },
    { label: '未现行', value: 'NST' },
    { label: '废止', value: 'WT' },
    { label: '其它', value: 'OTHER' },
  ]
}