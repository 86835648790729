<template>
  <div class="left_tree">
    <div class="list">
      <a-link
        v-for="item in list"
        :class="{ active: active == item.type }"
        :key="item.type"
        :hoverable="true"
        href="javascript:void(0)"
        @click="indexClick(item.type)"
      >
        <svg-icon :icon-class="item.icon"></svg-icon>
        <span>{{ item.title }}</span>
      </a-link>
      <a-divider />
      <div class="tree">
        <h3>标准体系分类</h3>
        <a-tree
          blockNode
          ref="tree"
          size="large"
          :default-selected-keys="selectedKeys"
          :selected-keys="selectedKeys"
          @select="selectClick"
          :data="treeData"
          :show-line="true"
          :default-expand-all="false"
        >
          <!-- <template #icon> </template> -->
        </a-tree>
      </div>
    </div>
  </div>
</template>

<script>
import merge from "webpack-merge";
export default {
  data() {
    return {
      list: [
        { icon: "all", title: "全部标准", type: "ALL" },
        { icon: "hashtag", title: "按标签搜索", type: "LABEL" },
      ],
      treeData: [],
      selectedKeys: [parseInt(this.$route.params.type)] || ["ALL"],
    };
  },
  computed: {
    active() {
      return this.$route.params.type || "ALL";
    },
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.TreeList().then(res => {
        let data = [], no = [];
        res.data.data.forEach(item => {
          if(item.parentId == 0) {
            data.push({ 
              title: item.cateName,
              id: item.cateId,  
              key: item.cateId,  
              children: [],
              parentId: 0,
            })
          }else {
            no.push({ 
              title: item.cateName,
              id: item.cateId, 
              key: item.cateId,  
              children: [], 
              parentId: item.parentId,
            })
          }
        });
        data.forEach((item, index) => {
          aa(item.id, data, index)
        })
        function aa(id, O, index) {
          let c = no.filter(item => item.parentId == id)
          if(c.length > 0) {
            O[index].children = c;
            c.forEach((item1, index1) => {
              item1.parentName = O[index].title
              aa(item1.id, O[index].children, index1)
            })
          }else {
            return;
          }
        }
        this.treeData = data
      })
    },
    indexClick(type) {
      // this.active = type;
      this.selectedKeys = [];
      if(type == 'LABEL') {
        this.$router.replace({
          params: merge(this.$route.params, { type: type }),
        });
      }else {
        this.$router.replace({
          params: merge(this.$route.params, { type: type }),
          query: merge(this.$route.query, { pager: 1 }),
        });
      }
    },
    selectClick(selectedKeys, event) {
      this.selectedKeys = selectedKeys;
      // this.active = null;
      this.$router.replace({
        params: merge(this.$route.params, { type: selectedKeys[0] }),
         query: merge(this.$route.query, { pager: 1 }),
      });
    },
  },
};
</script>

<style lang='scss'>
.left_tree {
  width: 280px;
  margin-right: 20px;
  padding: 0 10px;
  border-right: 1px solid #E4E7ED;

  .arco-link {
    width: 260px;
    height: 52px;
    line-height: 52px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #1d2129;
    line-height: 20px;
    padding: 0 10px;

    .svg-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .active {
    background: #f2faf9;
    color: #05a081;
  }

  .arco-divider-horizontal {
    margin: 15px 0;
  }

  .tree {
    padding: 0 10px;

    & > h3 {
      padding: 10px 0;
      font-size: 18px;
      line-height: 20px;
    }
  }
}
</style>